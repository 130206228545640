@import '../theme-bootstrap';
@import '../../../../node_modules/slick-carousel/slick/slick.scss';

// Default Variables
$slick-arrow-size: 30px !default;
$slick-dot-size-pc: 12px !default;
$slick-dot-size-mobile: 10px !default;
$slick-dot-size-mobile-inactive: 8px !default;
$slick-dot-color: $black !default;
$slick-dot-border-inactive: $color-white !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
/* Arrows */
.slick-prev,
.slick-next {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  height: $slick-arrow-size;
  line-height: $slick-arrow-size;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: $slick-arrow-size;
  z-index: 9;
  margin: 0 5px;
  // Because this is a bg image we want to make this easy to override with a 'slick--custom' class
  // If your carousel implements custom svg arrows via js add the slick--custom class to the arrow elements to exclude :before
  // Use-case for this is an inverted carousel that needs white arrows
  &:not(.slick--custom) {
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &:before {
      background: url('/sites/drjart/themes/drjart_base/img/icons/src/caret--right.svg') 0 0 no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: $slick-arrow-size;
      line-height: $slick-arrow-size;
      opacity: $slick-opacity-default;
      width: $slick-arrow-size;
    }
    &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
    }
  }
}

.slick-prev {
  #{$ldirection}: 15px;
  @include breakpoint($xlarge-up) {
    #{$ldirection}: 30px;
  }
  [dir='rtl'] & {
    #{$ldirection}: auto;
    #{$rdirection}: 0;
  }
  &:not(.slick--custom) {
    &:before {
      background: url('/sites/drjart/themes/drjart_base/img/icons/src/caret--left.svg') 0 0 no-repeat;
      transform: none;
      [dir='rtl'] & {
        transform: rotate(180deg);
      }
    }
  }
}

.slick-next {
  #{$rdirection}: 14px;
  @include breakpoint($xlarge-up) {
    #{$rdirection}: 30px;
  }
  [dir='rtl'] & {
    #{$ldirection}: 0;
    #{$rdirection}: auto;
  }
  &:not(.slick--custom) {
    &:before {
      transform: none;
      [dir='rtl'] & {
        transform: rotate(180deg);
      }
    }
  }
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  li {
    margin: 0 4px;
    position: relative;
    display: inline-block;
    height: $slick-dot-size-mobile;
    width: $slick-dot-size-mobile;
    padding: 0;
    cursor: pointer;
    @include breakpoint($landscape-up) {
      margin: 0 5px;
      width: $slick-dot-size-pc;
      height: $slick-dot-size-pc;
    }
    button {
      border: 0;
      background: transparent;
      display: block;
      height: $slick-dot-size-mobile;
      width: $slick-dot-size-mobile;
      outline: none;
      line-height: $slick-dot-size-mobile;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      @include breakpoint($landscape-up) {
        width: $slick-dot-size-pc;
        height: $slick-dot-size-pc;
        line-height: $slick-dot-size-pc;
      }
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        background: $slick-dot-color;
        border-radius: 50%;
        content: '';
        display: block;
        width: $slick-dot-size-mobile-inactive;
        height: $slick-dot-size-mobile-inactive;
        top: 0;
        #{$ldirection}: 0;
        position: absolute;
        line-height: $slick-dot-size-mobile-inactive;
        text-align: center;
        margin-top: 1px;
        @include breakpoint($landscape-up) {
          border: $slick-dot-border-inactive solid 2px;
          width: $slick-dot-size-pc;
          height: $slick-dot-size-pc;
          line-height: $slick-dot-size-pc;
          margin: 0;
        }
      }
    }
    &.slick-active button:before {
      border-color: $slick-dot-color;
      width: $slick-dot-size-mobile;
      height: $slick-dot-size-mobile;
      margin: 0;
      @include breakpoint($landscape-up) {
        width: $slick-dot-size-pc;
        height: $slick-dot-size-pc;
      }
    }
  }
}

.carousel-dots {
  position: relative;
}
